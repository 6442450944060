section.notification {
  background: #fff;
  padding-top: 140px;
  > div .container {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 15px;
  }
  header {
    position: absolute;
    left: 0;
    width: 450px;
    top: 0;
    img {
      width: 133px;
    }
  }
  footer {
    padding: 20px 0;
    margin: 10px auto;
    ul {
      list-style-type: none;
      list-style: none;
      border-bottom: 1px solid #bdc4c9;
      padding: 10px;
      max-width: 500px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      li {
        margin: 0 auto;
        a {
          color: #005ff8;
          font-size: 14px;
          text-decoration: none;
        }
      }
    }
    p {
      line-height: 40px;
      font-size: 13px;
      text-align: center;
      color: #868686;
    }
  }
}
@media (min-width: 700px) {
  body {
    background-color: var(--bg-dark);
    section.notification {
      max-width: 450px;
      > .shade {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 450px;
        background-color: #fff;
      }
    }
  }
}
