header.app {
    background: #19CD00;
    display: flex;
    flex-direction: row;
    height: 120px;
    box-sizing: border-box;
    padding: 40px 20px 20px 20px;
    justify-content: space-between;
    align-items: baseline;
    img {
        width: 35px;
        height: 35px;
    }
    p {
        font-family: 'Baloo 2', cursive;
        color: #fff;
        font-size: 30px;
        font-weight: 500;
        strong {
            font-weight: 700;
        }
    }
}
