.splash {
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    bottom: 0;
    max-width: 450px;
    img {
        width: 189px;
        left: 50%;
        top: 50%;
        height: 113px;
        position: absolute;
        margin: -56px 0 0 -95px;
    }
    p{
        text-align: center;
        position: absolute;
        bottom: 20px;
        width: 100%;
        color: #C4C4C4;
        strong {
            display: block;
            font-family: 'Baloo 2', cursive;
            color: #0088EE;
            font-size: 20px;
            font-weight: 400;
        }
    }
}