form {
  :-moz-placeholder, :-ms-input-placeholder, ::-moz-placeholder, ::-webkit-input-placeholder {
    font-weight: 300;
    line-height: 27px;
    color: #555555;
    font-size: 18px;
  }
  .form-input {
    margin-bottom: 15px;
  }
  input,
  label,
  select {
    display: block;
    color: #403f3f;
  }
  input,
  select,
  textarea {
    background: #fff;
    border: 0;
    border-bottom: 0.8px solid #dddddd;
    font-size: 18px;
    width: 100%;
    box-sizing: border-box;
    font-weight: 300;
    line-height: 27px;
    color: #555555;

    &.error {
      border: 1px solid #c02;
    }
  }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }
  span.error {
    color: red;
    line-height: 25px;
    font-size: 15px;
  }
}
@media (min-width: 700px) {
  form {
    .fl-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .col-50 {
        flex-basis: 48%;
      }
    }
  }
}
