div.checkbox {

    input {
        display: none;
    }

    div {
        line-height: 30px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 50px auto;

        span {
            background: #ddd;
            padding: 2px;
            margin-top: 5px;
            height: 20px;
            box-sizing: border-box;
            border-radius: 10px;
            cursor: pointer;
            position: relative;

            &.checked {
                background: #3BC63D;
            }

            &.checked i{
                left: 32px;
            }

            i {
                height: 16px;
                background: #fff;
                left: 2px;
                width: 16px;
                border-radius: 8px;
                display: block;
                position: absolute;
                transition: left 200ms ease-in-out;
            }
        }
    }
}