.notification {
  .cabletv {
    .update-receipt {
      width: 300px;
      padding: 20px;
      border-radius: 10px;
      background-color: #fff;
      h3 {
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        margin: 10px auto;
      }
      .triggers {
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        max-width: 360px;
        button {
          border: 0;
          background-color: #fff;
          line-height: 35px;
          border-radius: 5px;
          font-size: 16px;
          text-transform: uppercase;
          cursor: pointer;
        }
        button:first-child {
          flex-grow: 1;
          background-color: #32c832;
          color: #fff;
        }
        button:last-child {
          flex-basis: 120px;
          margin-left: 20px;
          border: 1px solid #fc7900;
          color: #fc7900;
        }
      }
    }
  }
}
