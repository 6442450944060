.notification {
  .order {
    padding: 0 20px;
    margin: 0 auto;
    h3 {
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 20px;
    }
    .metric {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-left: 5px;
      span {
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;
        i {
          color: #fff;
          width: 30px;
          height: 30px;
          font-style: normal;
          margin-left: 5px;
          text-align: center;
          line-height: 30px;
          border-radius: 15px;
          &.pending {
            background: #ce5642;
          }
          &.processing {
            background: #229b71;
          }
        }
      }
    }
    .info {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        border: 1px solid #999999;
        background-color: #f9f9f9;
        border-radius: 5px;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        color: #606060;
        padding: 6px 10px;
      }
    }
    .details {
      margin: 0 auto;
      border: 0.5px solid #cccccc;
      border-radius: 5px;
      .info {
        border-top: 0.5px solid #cccccc;
        span {
          padding: 0 10px;
        }
        strong.status-3 {
          color: #fc7900;
        }
        strong.status-2 {
          color: #2994c9;
        }
        strong.status-0,
        strong.status-1 {
          color: var(--bg-dark);
        }
      }
      .info:first-child {
        border-top: 0;
      }
    }
    button {
      border: 0;
      display: block;
      width: 100%;
      max-width: 365px;
      margin: 20px auto;
      line-height: 40px;
      border-radius: 5px;
      font-size: 16px;
      text-transform: uppercase;
      cursor: pointer;
      &.start-processing {
        background-color: #601dcf;
        box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
        color: #fff;
      }
      &.accept {
        background-color: var(--bg-dark);
        box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
        color: var(--text-light);
      }
      &.reject {
        background-color: #fc7900;
        box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
        color: var(--text-light);
      }
      &.download {
        background-color: #2994c9;
        box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
        color: var(--text-light);
      }
    }
  }
}
